body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-selector {
  border-radius: 12px !important;
}

.ant-select-dropdown {
  border-radius: 15px !important;
}

.ant-select-item {
  border-radius: 10px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}